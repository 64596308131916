import React, { FC } from 'react';

import { ICardData, renderCardReviewHeroTable } from '@throttleup/esi-components';
import ESIWrapper from '../../wrappers/ESIWrapper';
import { getSiteDomainWithoutProtocol } from '../../utils/helpers/replaceDomain';

interface ICardReviewHeroTableESI {
  cardId: number;
  cardData: ICardData | null;
}

const CardReviewHeroTableESI: FC<ICardReviewHeroTableESI> = ({ cardId, cardData = null }) => {
  const containerId = `hero_table_esi_${cardId}`;
  const extraParams = [getSiteDomainWithoutProtocol()];
  //Dummy data to load if the card data JSON is not yet loaded
  const dummyCardData = {
    upgpImage: { value: null, description: '' },
    upgpCardName: { value: 'Loading...', description: '' },
    cardReviewUrl: '#',
    welcomeOfferAmountCardTables: 'Loading points',
    upRating: '5',
    cardId: cardId,
    shortReview:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    whyWeLikeThisCardCardTables: 'Loading...',
    pros: 'Loading...',
    cons: 'Loading...',
    moreReadsOverride: [
      {
        title: '',
        uri: '',
        thumbnailUrl: '',
        thumbnailAlt: '',
        categoryName: '',
        categoryLink: '',
      },
    ],
    upgpAnnualFee: { value: 'N/A', description: '' },
    upgpInterestRate: { value: 'N/A', description: '' },
    shortcodes: [
      {
        acfFcLayout: 'html_shortcode',
        title: 'Dummy - Credit Recommendation',
        name: 'dummy-credit-recommended',
        code: '',
        content: '',
        renderedContent: 'Lorem ipsum dolor sit amet',
      },
    ],
  };
  const esiSrc = `/esi/components/?component=hero_table&card_id=${cardId}`;

  return (
    <ESIWrapper
      cardId={cardId}
      containerId={containerId}
      className="ccReview"
      esiSrc={esiSrc}
      fallbackRenderFunction={renderCardReviewHeroTable}
      dummyCardData={dummyCardData}
      extraParams={extraParams}
      cardDataFromProps={cardData}
    />
  );
};

export default CardReviewHeroTableESI;
