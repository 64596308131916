import React, { useContext } from 'react';
import { graphql, PageProps } from 'gatsby';

import CardDataContext from '../../context/CardDataContext';
import { processHTMLContent } from '../../utils/processHTMLContent';
import TopSlider from '../../components/TopSlider';
import FullWidthTemplateHeader from '../../components/Article/components/Templates/FullWidthTemplate/components/FullWidthTemplateHeader/FullWidthTemplateHeader';
import {
  getAuthorDetails,
  getCategoriesBreadcrumbs,
  getTooltipDetails,
  getFaqs,
  getRelatedPosts,
  getTopContentItemsReviews,
} from '../../utils/helpers';
import { getComments } from '../../utils/helpers/comments';
import Seo from '../../components/Seo';
import {
  ITopRelatedContent,
  IWpTopCCContentForReviews,
} from '../../components/TopRelatedContent/TopRelatedContent.def';
import { TopRelatedContent } from '../../components/TopRelatedContent';
import ContentDislosure from '../../components/ContentDisclosure/ContentDisclosure';
import Faqs from '../../components/Faqs/Faqs';
import Comments from '../../components/Comments/Comments';
import AuthorInfo from '../../components/AuthorInfo/AuthorInfo';
import RelatedPosts from '../../components/RelatedPosts/RelatedPosts';
import { processCopyright } from '../../utils/helpers';
import CardReviewHeroTableESI from '../../components/CardReviewHeroTableESI';
import { PostContext } from '../../components/TopSlider/TopSlider.def';
import DisclosuresESI from '../../components/DisclosuresESI/DisclosuresESI';
import getTrustDropdownData from '../../utils/static-queries/getTrustDropdownData';
import TrustDropdown from '../../components/TrustDropdown/TrustDropdown';
import { getTopCreditCardContentFromWp } from '../../utils/static-queries';
import HTPageWrapper from '../../wrappers/HTPageWrapper';
import { HT_DEFAULT_PAGE_NAME } from '../../constants';

interface IDataTemplate {
  post: Queries.WpPost;
  isPreview?: boolean;
}

interface IPageContextTemplate {
  pageContext: PostContext;
  databaseId: number;
  templateName: string;
  isPreview: boolean | undefined;
  cardDataJsonPreview: string;
}

const CreditCardReviewTemplate = ({
  data,
  pageContext,
  location,
}: PageProps<IDataTemplate, IPageContextTemplate>) => {
  const {
    post: {
      content,
      author,
      title,
      categories,
      modifiedGmt,
      dateGmt,
      postEditorSettings,
      tags,
      cardMentionsAll,
      nonAffiliateAll,
      fieldsForPosts,
      databaseId,
      commentCount,
      comments: postComments,
      commentStatus,
      cardDataJsonPreview,
    },
    isPreview,
  } = data;
  const { cardDataJson } = useContext(CardDataContext);
  const cardData = isPreview
    ? cardDataJsonPreview && JSON.parse(cardDataJsonPreview) //For previews, specifically for previews on revisions, we need to use the card data passed in the props from the preview page
    : cardDataJson?.data?.find((card) => card.cardId === databaseId) || null;

  const tooltipDetails = getTooltipDetails(author?.node, postEditorSettings);

  // Retrieve all the Top CC Content Reviews Items - business and personal, from UpOption
  const creditCardReviewsTopContentData = getTopCreditCardContentFromWp();
  const topContentVariantsData =
    creditCardReviewsTopContentData?.creditCardReviewsTopContent?.upgpCcReviewsTopContentVariants ||
    null;
  let topContentVariants: IWpTopCCContentForReviews[] = [];

  if (topContentVariantsData && Array.isArray(topContentVariantsData)) {
    topContentVariants = topContentVariantsData;
  }

  let topRelatedContent: ITopRelatedContent | null = null;
  const postTags = tags?.nodes || null;

  if (postTags && postTags?.length) {
    postTags.forEach((tag) => {
      if (tag) {
        const { name } = tag;
        let topCCReviewsVariant = '';
        if (name?.toLowerCase().includes('business')) {
          topCCReviewsVariant = 'business';
        }
        if (name?.toLowerCase().includes('personal')) {
          topCCReviewsVariant = 'personal';
        }
        const filteredTopContent: IWpTopCCContentForReviews | undefined = topContentVariants.filter(
          (el) => el?.upgpCcReviewsTopContentType?.includes(topCCReviewsVariant),
        )[0];
        const topContentBlockToDisplay = getTopContentItemsReviews(filteredTopContent);

        if (topContentBlockToDisplay) {
          topRelatedContent = {
            categories: topContentBlockToDisplay.categories,
            title: topContentBlockToDisplay.title,
          };
        }
      }
    });
  }
  const faqs = getFaqs(fieldsForPosts?.upgpPostFaqs);
  const numberOfComments = commentCount ? commentCount : 0;
  const commentsClosed = commentStatus === 'closed';
  const comments = getComments(postComments, databaseId);
  const toggleElements: string[] =
    typeof fieldsForPosts?.upgpPostToggleElements?.length !== 'undefined'
      ? fieldsForPosts.upgpPostToggleElements.map((el) => el ?? '')
      : [];
  const showCommentsDisclosure = !toggleElements.includes(
    ' _upgp_disclosure_text_above_comments_disabled',
  );
  const authorDetails = getAuthorDetails(author?.node);
  const authorThumbnail = {
    src: authorDetails.imageUrl,
    alt: authorDetails.imageAlt,
  };

  const relatedPosts = getRelatedPosts(fieldsForPosts?.upgpPostRelatedPosts);

  const trustDropdownData = fieldsForPosts?.upgpUseTrustDropdown ? getTrustDropdownData() : null;

  // Using the context coming from the previews.
  const previewContext = {
    databaseId: data?.post?.databaseId || 0,
    tags: data?.post?.tags || null,
    link: data?.post?.uri || '',
    categories: data?.post?.categories || null,
    templateName: data?.post?.template?.templateName || '',
    cardDataJsonPreview: data?.post?.cardDataJsonPreview || '',
    isPreview: isPreview,
  };

  return (
    <HTPageWrapper
      category={'Credit Cards, Reviews'}
      title={title || HT_DEFAULT_PAGE_NAME}
      location={location}
    >
      <TopSlider context={isPreview ? previewContext : pageContext} />
      <article>
        <div className="credit-card-template-header">
          <div className="container">
            <FullWidthTemplateHeader
              title={title || ''}
              dateModified={modifiedGmt || ''}
              datePublished={dateGmt || ''}
              featuredImage={null}
              author={authorDetails}
              breadCrumbs={getCategoriesBreadcrumbs(categories)}
              tooltip={tooltipDetails}
            />
          </div>
        </div>
        <div className="credit-card-template">
          <div className="container">
            <ContentDislosure />
            <CardReviewHeroTableESI cardId={databaseId} cardData={isPreview ? cardData : null} />
            {trustDropdownData && (
              <TrustDropdown
                upgpTrustDropdownContent={trustDropdownData.content}
                upgpTrustDropdownTitle={trustDropdownData.title}
              />
            )}
          </div>

          <div className="page-content">
            <div className="container">
              <div className="credit-card-review-content">
                {processHTMLContent(processCopyright(content) || '', '/TE/', 'Credit Card Review')}
                <DisclosuresESI
                  parentId={databaseId}
                  cardMentions={cardMentionsAll}
                  nonAffiliate={nonAffiliateAll}
                />
              </div>
              <footer>
                {faqs && <Faqs faqs={faqs} />}
                {authorDetails && (
                  <AuthorInfo
                    authorName={authorDetails.name}
                    content={authorDetails.bio || ''}
                    link={authorDetails.url}
                    socials={authorDetails.socials}
                    thumbnail={authorThumbnail}
                  />
                )}
                {comments && (
                  <Comments
                    comments={comments}
                    total={numberOfComments}
                    postDatabaseId={databaseId}
                    commentsClosed={commentsClosed}
                    showCommentsDisclosure={showCommentsDisclosure}
                  />
                )}
              </footer>
            </div>
            {topRelatedContent && <TopRelatedContent {...topRelatedContent} />}
            {relatedPosts && <RelatedPosts relatedPosts={relatedPosts} />}
          </div>
        </div>
      </article>
    </HTPageWrapper>
  );
};

export default CreditCardReviewTemplate;

export const Head = ({ data }: PageProps<IDataTemplate, IPageContextTemplate>) => {
  const { author, seo, fieldsForPosts, fieldsForCreditCards, title, categories } = data.post;
  let cardData = null;
  if (fieldsForCreditCards) {
    cardData = {
      image: fieldsForCreditCards.upgpCreditCardImageGroup?.upgpCreditCardImage?.sourceUrl || '',
      cardName: title || '',
      pros: fieldsForCreditCards.upgpCreditCardPros || '',
      cons: fieldsForCreditCards.upgpCreditCardCons || '',
      quickSummary: fieldsForCreditCards.upgpCreditCardQuickSummary || '',
      shortReview: fieldsForCreditCards.upgpCreditCardShortReview || '',
      upRating: fieldsForCreditCards.upgpCreditCardUpRating || '',
    };
  }
  return (
    <>
      <Seo
        wpSeo={seo}
        isReviewArticle={true}
        faqs={fieldsForPosts?.upgpPostFaqs}
        author={author?.node}
        cardData={cardData}
        categories={categories}
      />
    </>
  );
};

export const query = graphql`
  query CreditCardReview($id: String) {
    post: wpPost(id: { eq: $id }) {
      ...PostData
      fieldsForCreditCards {
        upgpCreditCardCons
        upgpCreditCardPros
        upgpCreditCardQuickSummary
        upgpCreditCardImageGroup {
          upgpCreditCardImage {
            sourceUrl
          }
        }
        upgpCreditCardShortReview
        upgpCreditCardUpRating
      }
    }
  }
`;
